import React, { useState } from 'react'
import CategoryListTable from '../../components/category/CategoryListTable'
import AddCategoryModal from '../../components/category/AddCategoryModal'
import CourseCategoryApis from '../../queries/category'
import EditCategoryModal from '../../components/category/EditCategoryModal'

function Category() {
    const [page, setpage] = useState(1)
    const [length, setlength] = useState(10)
    const [ctgryName, setctgryName] = useState('')
    const [showCtgryAddModal, setshowCtgryAddModal] = useState(false)
    const [catgryEditData, setcatgryEditData] = useState()
    const [catgryEditModal, setcatgryEditModal] = useState(false)
    const handleClose = () => {
        setshowCtgryAddModal(false)
    }
    const getCategoryEditData = (data) => {
        setcatgryEditData(data)
        setcatgryEditModal(true)
    }
    const handleCloseEditModal=()=>{
        setcatgryEditModal(false)
    }
    const { data: Categories, refetch: getCategories, isLoading } = CourseCategoryApis.GetCategories(page, length, ctgryName)
    return (
        <>
            <div className="dashboard-main-body">
                <CategoryListTable
                    page={page}
                    setpage={setpage}
                    length={length}
                    setshowCtgryAddModal={setshowCtgryAddModal}
                    totalEntries={Categories?.data?.totalEntries}
                    tableData={Categories?.data?.Category}
                    isLoading={isLoading}
                    setctgryName={setctgryName}
                    ctgryName={ctgryName}
                    getCategoryEditData={getCategoryEditData}
                    getCategories={getCategories}

                />

                <AddCategoryModal
                    show={showCtgryAddModal}
                    close={handleClose}
                    getCategories={getCategories}
                />
                {
                    catgryEditData &&
                    <EditCategoryModal
                        show={catgryEditModal}
                        close={handleCloseEditModal}
                        getCategories={getCategories}
                        data={catgryEditData}
                    />
                }

            </div>
        </>
    )
}

export default Category