import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import logo from '../../assets/images/logo.png'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loginschema from '../../schema/login';
import { useAuth } from '../../context/AuthContext';
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import AuthApis from '../../queries/auth';
import Aos from 'aos';

function Login() {
    const { login } = useAuth()
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(Loginschema),
    })
    const navigate = useNavigate()
    const userLogin = AuthApis.LoginUser()
    const [eyeOpen, seteyeOpen] = useState(false)
    const loginHandler = async (data) => {
        const isValid = await trigger()
        if (!isValid) {
            return toast.error("error....")
        }
        data.role = ["admin","tutor"]
        const response = await userLogin.mutateAsync(data)
        if (response.status) {
            login(response?.data?.role)
            navigate('/dashboard')
        } else {
            setError(response?.error?.field, { message: response?.error?.message })
        }

    }
    useEffect(() => {
        Aos.init()
    }, [])
    return (
        <section className="login_box_sec" data-aos="fade-right">
            <div className="login_box">
                <div className="max-w-464-px mx-auto w-100">
                    <div style={{textAlign:'center'}}>
                        <a href="index.html" className="mb-40 max-w-290-px">
                            <img src='/image/CareTeam-Logo-Final-1_page-0001-removebg-preview.png' alt="logo" className='w-75'/>
                        </a>
                        <h4 className="mb-12">Sign In to your Account</h4>
                        <p className="mb-32 text-secondary-light text-lg">
                            Welcome back! please enter your detail
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(loginHandler)}>
                        <div className={`icon-field ${errors.email ? 'pb-2' : 'mb-16'}`}>
                            <span className="icon top-50 translate-middle-y">
                                <Icon icon="mage:email"></Icon>
                            </span>
                            <input
                                type="email"
                                placeholder="Email"
                                className={`form-control h-56-px bg-neutral-50 radius-12 ${errors?.email ? 'form-error' : ''}`}
                                {...register("email")}
                            />

                        </div>
                        <div className="invalid-feedback-form mb-2">
                            {errors?.email?.message}
                        </div>
                        <div className={`position-relative mb-20`}>
                            <div className={`icon-field ${errors.password ? 'pb-2' : 'mb-16'}`}>
                                <span className="icon top-50 translate-middle-y">
                                    <Icon icon="solar:lock-password-outline"></Icon>
                                </span>
                                <input
                                    type={`${eyeOpen ? 'text' : 'password'}`}
                                    id="your-password"
                                    placeholder="Password"
                                    {...register("password")}
                                    className={`form-control h-56-px bg-neutral-50 radius-12 ${errors.password ? 'form-error' : ''}`}
                                />

                            </div>
                            <div class="invalid-feedback-form ">
                                {errors?.password?.message}
                            </div>

                            {/* <span
                                className="toggle-password ri-eye-line cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light"
                                data-toggle="#your-password"
                            ></span> */}
                            {
                                eyeOpen ?
                                    <Icon icon="pajamas:eye" width="24" height="24"
                                        className='cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light'
                                        onClick={() => seteyeOpen(!eyeOpen)} />
                                    :
                                    <Icon icon="ph:eye-closed"
                                        width="24" height="24"
                                        className='cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light'
                                        onClick={() => seteyeOpen(!eyeOpen)}
                                    />
                            }
                        </div>

                        <div>
                            <div className="d-flex justify-content-between gap-2">
                                {/* <div className="form-check style-check d-flex align-items-center">
                                    <input
                                        style={{ appearance: "auto" }}
                                        type="checkbox"
                                        id="remeber"
                                    />
                                    <label className="form-check-label" htmlFor="remeber">
                                        Remember me
                                    </label>
                                </div> */}
                                {/* <a
                                    href="javascript:void(0)"
                                    className="text-primary-600 fw-medium"
                                >
                                    Forgot Password?
                                </a> */}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary text-sm btn-sm px-12 py-16 w-100 radius-12 mt-32"
                            disabled={userLogin?.isPending}
                        >
                            Sign In
                        </button>
                    </form>
                </div>
            </div>
        </section>

    )
}

export default Login