import React from 'react'
import Pagination from '../../common/Pagination'
import TableSkeleton from '../../utils/skeletons/TableSkeleton'
import Skeleton from 'react-loading-skeleton'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import noDataimg from '../../assets/images/No data-cuate.png'
import { Form } from 'react-bootstrap'
import CourseCategoryApis from '../../queries/category'
import toast from 'react-hot-toast'

function CategoryListTable(
    {
        page,
        setpage,
        length,
        setshowCtgryAddModal,
        totalEntries,
        tableData,
        isLoading,
        setctgryName,
        ctgryName,
        getCategoryEditData,
        getCategories
    }
) {
    const dltCatrgory = CourseCategoryApis.DltCategory()

    const dltHandler = async (id) => {
        const response = await dltCatrgory.mutateAsync({ catId: id })
        if (response.status) {
            toast.success('category deleted')
            const response = await getCategories()
            if (response?.data?.data?.Category?.rows?.length == 0) {
                setpage(1)
            }
        } else {
            toast.error('category delete failed')
        }
    }
    return (
        <div className="card h-100">
            <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
                <div className="d-flex align-items-center flex-wrap gap-3">

                    <form className="navbar-search">
                        <input type="text" className="bg-base h-40-px w-auto" name="search" placeholder="Enter category name"
                            value={ctgryName}
                            onChangeCapture={(e) => setctgryName(e.target.value)}
                        />
                        <Icon icon="ion:search-outline" className="icon"></Icon>
                    </form>
                </div>
                {
                    isLoading ?
                        <Skeleton width={200} height={50} baseColor='#f5f6fa' />
                        :
                        <a href="#" className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2"
                            onClick={() => setshowCtgryAddModal(true)}
                        >
                            <Icon icon="ic:baseline-plus" className="icon text-xl line-height-1"></Icon>
                            Add Category
                        </a>
                }
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-style mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Course Count</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        {
                            isLoading ?
                                <TableSkeleton rowCount={5} colCount={5} />

                                :
                                <tbody>
                                    {

                                        tableData?.rows?.length > 0 ?
                                            tableData?.rows?.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            <Form.Control as="textarea" rows={2} value={item.description} />
                                                        </td>
                                                        <td>{item.courseCount}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-10">
                                                                <button
                                                                    type="button"
                                                                    className="card-edit-button text-success-600"
                                                                    onClick={() => getCategoryEditData(item)}
                                                                >
                                                                    <Icon
                                                                        icon="lucide:edit"
                                                                        className="icon text-lg line-height-1"
                                                                    ></Icon>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="card-delete-button text-danger-600"
                                                                    disabled={!item?.dltStatus}
                                                                    style={{ cursor: !item.dltStatus ? 'not-allowed' : 'pointer' }}
                                                                    onClick={() => dltHandler(item.id)}
                                                                >
                                                                    <Icon
                                                                        icon="fluent:delete-24-regular"
                                                                        className="icon text-lg line-height-1"
                                                                    ></Icon>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    <img src={noDataimg} className="w-50" alt="No Data" />
                                                </td>
                                            </tr>

                                    }

                                </tbody>
                        }

                    </table>
                </div>
                <Pagination
                    totalEntries={totalEntries}
                    entriesPerPage={length}
                    currentPage={page}
                    onPageChange={setpage}
                />
            </div>
        </div>
    )
}

export default CategoryListTable