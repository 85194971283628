import { useMutation, useQuery } from '@tanstack/react-query'
import { addCategory, dltCategory, getCategories, updateCategory } from '../service/category/categoryService';



const CourseCategoryApis = {
    AddCategory: () => {
        const response = useMutation({
            mutationFn: (data) => addCategory(data)
        })
        return response
    },
    GetCategories: (start, length, name) => {
        const response = useQuery({
            queryKey: ['get-categories', start, length, name],
            queryFn: () => getCategories(start, length, name)
        })
        return response;
    },
    UpdateCategory: () => {
        const response = useMutation({
            mutationFn: (data) => updateCategory(data)
        })
        return response
    },
    DltCategory: () => {
        const response = useMutation({
            mutationFn: (data) => dltCategory(data)
        })
        return response
    }
}
export default CourseCategoryApis;