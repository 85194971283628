import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form';
import CategorySchema from '../../schema/category';
import { yupResolver } from '@hookform/resolvers/yup';
import CourseCategoryApis from '../../queries/category';
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next';
function AddCategoryModal({ show, close, getCategories }) {
    const {t}=useTranslation()
    const addCourse = CourseCategoryApis.AddCategory()
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CategorySchema),
    })
    const submitHandler = async (data) => {
        const response = await addCourse.mutateAsync(data)
        if (response.status) {
            await getCategories()
            toast.success('category added successfully')
            reset()
            close()
        } else {
            if (response.error) {
                toast.error(t(response?.error?.message))
            } else {
                toast.error('category added failed')

            }
        }
    }
    const modalClose = () => {
        close()
        reset()
    }
    return (
        <Modal show={show} onHide={close} size="lg" aria-labelledby="exampleModalLabel" centered>
            <Modal.Header closeButton>
                <Modal.Title id="exampleModalL abel"><h6>Add Category</h6></Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form onSubmit={handleSubmit(submitHandler)}>
                    <div className="row">
                        <div className="col-sm-12">
                            <Form.Group controlId="name" className="mb-20">
                                <Form.Label className={"form-label fw-semibold text-primary-light text-sm mb-8"}>
                                    Category Name <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control type="text" {...register('name')} placeholder="Enter course name" className={`radius-8 ${errors?.name ? 'is-invalid' : ''}`} />
                                <div class="invalid-feedback">
                                    {errors?.name?.message}
                                </div>
                            </Form.Group>
                        </div>

                        <div className="col-sm-12">
                            <Form.Group controlId="desc" className="mb-20">
                                <Form.Label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                    Description <span className="text-danger-600">*</span>
                                </Form.Label>
                                <Form.Control as="textarea" {...register('description')} placeholder="Write description..." className={`radius-8 ${errors?.description ? 'is-invalid' : ''}`} />
                                <div class="invalid-feedback">
                                    {errors?.description?.message}
                                </div>
                            </Form.Group>
                        </div>

                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                        <Button variant="outline-danger" onClick={modalClose} className="px-56 py-11 radius-8">
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" className="px-56 py-12 radius-8" disabled={addCourse?.isPending}>
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AddCategoryModal