import api from "../../api/axios.instance"

export const addCategory = async (data) => {
    try {
        const response = await api.post('common/category', data)
        return response
    } catch (error) {
        return error
    }
}
export const getCategories = async (start, length, name) => {
    try {
        const response = await api.get('common/category', { params: { start, length, name } })
        return response
    } catch (error) {
        return error
    }
}
export const updateCategory = async (data) => {
    try {
        const response = await api.put('common/category', data)
        return response
    } catch (error) {
        return error
    }
}
export const dltCategory = async (data) => {
    try {
        const response = await api.delete('common/category', { data })
        return response
    } catch (error) {
        return error
    }
}