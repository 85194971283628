import React from 'react'
import moment from 'moment/moment';
export default function Footer() {
    const currentYear = moment().year();
    return (
        <footer className="d-footer">
            <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                    <p className="mb-0">Copyright @ The Care Team. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
