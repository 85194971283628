import * as yup from "yup"

const CourseSchema = yup
    .object({
        title: yup.string().required("Course Name is required"),
        description: yup.string().required(),
        overview: yup.string().required(),
        level: yup.string().required(),
        requirements: yup.string().required(),
        categoryId: yup.string().required("Category is required"),
        price: yup.string().matches(/^[0-9]+$/, "Only number allowed").required(),
        pv: yup.string().matches(/^[0-9]+$/, "Only numbers allowed").required()
    })
    .required()

export default CourseSchema